import { useContext, useEffect, useState } from 'react'
import { DocumentProps, TableContext } from '../../contexts/TableContext'
import { Pagination } from '@mui/material'
import { v4 as uuid } from 'uuid'
import { Socket } from 'socket.io-client'
import { api } from '../../services/api'
import { CompaniesContext } from '../../contexts/CompaniesContext'
import { useLocation } from 'react-router-dom'
import { matchFilesType } from '../../utils/match-files-type'
import { error, warn } from '../../utils/browser-loggers'

import search from '../../assets/icons/search.svg'
import erase from '../../assets/icons/erase.svg'
import key from '../../assets/icons/key.svg'
import download from '../../assets/icons/download.svg'
import print from '../../assets/icons/print.svg'
import filter from '../../assets/icons/filter.svg'
import noDocument from '../../assets/icons/no__document.svg'

import TableRow from '../../components/TableRow'
import Header from '../../components/Header'
import TableHeader from '../../components/TableHeader'
import SubHeader from '../../components/SubHeader'
import DownloadRequests from '../../components/Modals/DownloadRequests'
import PDFDocumentsGenerator from '../../components/PDFDocumentsGenerator'
import DownloadConfirmationItems from '../../components/Modals/DownloadConfirmationItems'

import './styles.css'

interface FilesPageProps {
  socket: Socket
}

interface DownloadSocketCallback {
  bucket: string
  id_solicitacao: number
  socket: string
  url: string
}

interface DocsLocationProps {
  month: string
}

export default function Docs({ socket }: FilesPageProps) {
  const COMPONENTS_PER_PAGE = 10

  const location = useLocation()
  const state = location.state as DocsLocationProps
  const { month } = state

  const {
    allChecked,
    handleCheck,
    selectedItems,
    handleFilterChange,
    filterProps,
    clearSelectedItems,
    selectedYear,
    documentType,
    documentsInContext,
    setDocumentsInContext,
    setDocuments,
    documents,
  } = useContext(TableContext)

  const { selectedClient, selectedSerie } = useContext(CompaniesContext)

  const [pages, setPages] = useState<number>(0)
  const [socketChannel, setSocketChannel] = useState<string>('')
  const [waitingDownloadRequest, setWaitingDownloadRequest] = useState(false)
  const [modalDownloadRequestsOpen, setModalDownloadRequestsOpen] = useState(false)
  const [modalDownloadConfirmationItemsOpen, setModalDownloadConfirmationItemsOpen] = useState(false)
  const [pageData, setPageData] = useState<any[] | null>([documents && documents.slice(0, COMPONENTS_PER_PAGE)])
  const [waitingAPIRequest, setWaitingAPIRequest] = useState(true)

  const LAST_DAY_OF_MONTH = new Date(Number(selectedYear), Number(month), 0).getDate()

  useEffect(() => {
    setupSocket()
    setupFilterDates()
    handlePaginationChange(null, 1)
    filterAllDocumentsOfMonth()
  }, [selectedSerie, selectedClient, selectedYear, documentType])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (documents?.length) {
      setPageData(documents && documents.slice(startIndex, lastIndex))
    }
  }

  function setupFilterDates() {
    const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')
    const actualYear = new Date().getFullYear().toString()

    filterProps.initialDate = `${selectedYear}-${month}-01`
    filterProps.finalDate =
      month === actualMonth && selectedYear === actualYear
        ? `${selectedYear}-${month}-${new Date().getDate().toString().padStart(2, '0')}`
        : `${selectedYear}-${month}-${LAST_DAY_OF_MONTH}`
  }

  function setupSocket() {
    const newSocketChannel = uuid()
    setSocketChannel(newSocketChannel)
    socket.on(newSocketChannel, (client: DownloadSocketCallback) => {
      setWaitingDownloadRequest(false)
      clearSelectedItems()
      window.open(`https://${client.bucket}.s3.sa-east-1.amazonaws.com/${client.url}`, '_blank')
    })
  }

  async function downloadItems(documentExtension: 'pdf' | 'xml') {
    setWaitingDownloadRequest(true)
    setModalDownloadConfirmationItemsOpen(false)
    clearSelectedItems()

    const sameRequestBefore = await verifyIfExistsAEqualRequest()
    if (sameRequestBefore) {
      setWaitingDownloadRequest(false)
      window.open(`https://requests-zips.s3.sa-east-1.amazonaws.com/${sameRequestBefore}`, '_blank')
      return
    }

    const files = selectedItems.map((item) => {
      return {
        id: item.id,
        fileName: item[`s3_${documentExtension}_nome_arquivo`],
        key: item.chave,
      }
    })

    await api
      .post('/s3/download', {
        arquivos: files,
        socket: socketChannel,
        tipo_documento: filterProps.type,
        id_cliente: selectedClient.id,
        bucket: `/${documentType}-${documentExtension}`,
      })
      .then((response) => {
        if (response.status !== 200) {
          warn(`download items response status => ${response.status}`, { warn: response.data })
        }
      })
      .catch((err) => error('fail on download items', { err }))
  }

  async function verifyIfExistsAEqualRequest(): Promise<string | null> {
    const files = selectedItems.map((item) => item.id)

    try {
      const response = await api.get(
        `/solicitacoes_download/verificar?arr=${JSON.stringify(files)}&id_cliente=${selectedClient.id}`
      )
      const { url } = response.data
      return url
    } catch (err) {
      return null
    }
  }

  function handleFilter(e: any) {
    e.preventDefault()
    clearSelectedItems()

    const { initialDate, finalDate, cfop, documentNumber, key } = filterProps

    let documentsToFilter = documentsInContext || []

    if (documentNumber || key) {
      if (documentNumber) {
        documentsToFilter = documentsToFilter.filter((document) => document.numero == Number(documentNumber))
      } else {
        if (key?.includes(',')) {
          const keys = key?.split(',')

          let arrOfDocuments: DocumentProps[] = []

          for (const k of keys) {
            const document = documentsToFilter.find((document) => document.chave === k)
            document && arrOfDocuments.push(document)
          }

          documentsToFilter = arrOfDocuments
        } else {
          const document = documentsToFilter.find((document) => document.chave === key)
          if (document) documentsToFilter = [document]
        }
      }
    } else {
      if (initialDate && finalDate) {
        const initialDateInTimestamp = new Date(new Date(`${initialDate}, 00:00:00`)).getTime() - 1
        const finalDateInTimestamp = new Date(`${finalDate}, 00:00:00`).getTime() + 1

        documentsToFilter = documentsToFilter?.filter(
          (document) =>
            new Date(new Date(`${document.data_emissao}`).setUTCHours(4)).getTime() > initialDateInTimestamp &&
            new Date(new Date(`${document.data_emissao}`).setUTCHours(4)).getTime() < finalDateInTimestamp
        )

        if (cfop) {
          documentsToFilter = documentsToFilter?.filter((document) =>
            document.cfop.toLowerCase().includes(cfop.toLowerCase())
          )
        }
      }
    }

    const dataSplited = String(documentsToFilter && documentsToFilter.length / COMPONENTS_PER_PAGE).split('.')

    if (dataSplited[1]) {
      setPages(Number(dataSplited[0]) + 1)
    } else {
      setPages(Number(dataSplited[0]))
    }

    setPageData(documentsToFilter.slice(0, COMPONENTS_PER_PAGE))
    setDocuments(documentsToFilter)
  }

  function handleFormReset() {
    handleFilterChange({ key: 'initialDate', value: '' })
    handleFilterChange({ key: 'finalDate', value: '' })
    handleFilterChange({ key: 'cfop', value: '' })
    handleFilterChange({ key: 'key', value: '' })
    handleFilterChange({ key: 'documentNumber', value: '' })
  }

  async function filterAllDocumentsOfMonth() {
    const request = await api.get(
      `/arquivos/filtrar/mes?client=${
        selectedClient.id
      }&year=${selectedYear}&series=${selectedSerie}&documentType=${matchFilesType(documentType)}&month=${month}`
    )

    const { data } = request

    setDocuments(data)

    const dataSplited = String(data && data.length / COMPONENTS_PER_PAGE).split('.')

    if (dataSplited[1]) {
      setPages(Number(dataSplited[0]) + 1)
    } else {
      setPages(Number(dataSplited[0]))
    }

    setPageData(data.slice(0, COMPONENTS_PER_PAGE))

    setDocumentsInContext(data)
    setWaitingAPIRequest(false)
  }

  return (
    <div className="docs">
      <Header />

      <div className="container">
        {Object.keys(selectedClient).length ? (
          <>
            <SubHeader />

            <div className="tables">
              <div className="main box">
                <TableHeader />

                <div className="content">
                  <div className="actions">
                    <form
                      onSubmit={(e) => {
                        handleFilter(e)
                      }}
                    >
                      <div className="row">
                        <div className="input__block">
                          <label htmlFor="initialDate">data inicial</label>
                          <div className="input date">
                            <input
                              required={
                                !!(Object.keys(filterProps).find(
                                  (key, keyIndex) =>
                                    !['key', 'documentNumber'].includes(key) && Object.values(filterProps)[keyIndex]
                                )
                                  ? true
                                  : false)
                              }
                              value={filterProps.initialDate}
                              type="date"
                              id="initialDate"
                              name="initialDate"
                              min={`${selectedYear}-${month}-01`}
                              max={
                                (new Date().getMonth() + 1).toString().padStart(2, '0') !== month
                                  ? `${selectedYear}-${month}-${LAST_DAY_OF_MONTH}`
                                  : `${selectedYear}-${month}-${new Date().getDate().toString().padStart(2, '0')}`
                              }
                              onChange={(e) => handleFilterChange({ key: 'initialDate', value: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="input__block">
                          <label htmlFor="finalDate">data final</label>
                          <div className="input date">
                            <input
                              required={
                                !!(Object.keys(filterProps).find(
                                  (key, keyIndex) =>
                                    !['key', 'documentNumber'].includes(key) && Object.values(filterProps)[keyIndex]
                                )
                                  ? true
                                  : false)
                              }
                              value={filterProps.finalDate}
                              type="date"
                              id="finalDate"
                              name="finalDate"
                              min={`${selectedYear}-${month}-01`}
                              max={
                                (new Date().getMonth() + 1).toString().padStart(2, '0') !== month
                                  ? `${selectedYear}-${month}-${LAST_DAY_OF_MONTH}`
                                  : new Date().getFullYear().toString() !== selectedYear
                                  ? `${selectedYear}-${month}-${LAST_DAY_OF_MONTH}`
                                  : `${selectedYear}-${month}-${new Date().getDate().toString().padStart(2, '0')}`
                              }
                              onChange={(e) => handleFilterChange({ key: 'finalDate', value: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="input__block cfop">
                          <label htmlFor="cfop">natureza operação</label>
                          <div className="input">
                            <input
                              type="text"
                              id="cfop"
                              placeholder="ex: 5102, venda de produtos..."
                              name="cfop"
                              onChange={(e) => {
                                handleFilterChange({ key: 'cfop', value: e.target.value })
                              }}
                            />
                          </div>
                        </div>

                        <button
                          type="reset"
                          className="button__md"
                          onClick={() => handleFormReset()}
                          id="clear"
                          title="LIMPAR CAMPOS"
                        >
                          <img src={erase} alt="erase" />
                        </button>
                      </div>

                      <div className="row" id="search">
                        <div className="input__block note__n">
                          <div className="input">
                            <input
                              type="text"
                              placeholder="nº da nota"
                              onChange={(e) => handleFilterChange({ key: 'documentNumber', value: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="input__block">
                          <div className="input">
                            <img src={key} alt="key" />
                            <input
                              type="text"
                              placeholder="pesquisar por chave..."
                              onChange={(e) => handleFilterChange({ key: 'key', value: e.target.value })}
                            />
                          </div>
                        </div>

                        <button type="submit" id="searchButton">
                          <img src={search} alt="search" />
                        </button>

                        <button
                          type="reset"
                          className="button__sm"
                          onClick={() => handleFormReset()}
                          id="clear"
                          title="LIMPAR CAMPOS"
                        >
                          <img src={erase} alt="erase" />
                        </button>
                      </div>
                    </form>

                    <div className="cloud__actions">
                      <div className="row">
                        <button
                          id="download"
                          className={waitingDownloadRequest ? 'button__loading' : ''}
                          disabled={selectedItems.length < 2}
                          onClick={() => setModalDownloadConfirmationItemsOpen(true)}
                        >
                          <img src={download} alt="download" />
                        </button>
                        <button
                          id="print"
                          disabled={selectedItems.length === 0}
                          onClick={() =>
                            PDFDocumentsGenerator(
                              selectedItems,
                              filterProps.initialDate,
                              filterProps.finalDate,
                              month,
                              selectedYear,
                              documentType
                            )
                          }
                        >
                          <img src={print} alt="print" />
                        </button>
                      </div>

                      <button id="downloads" onClick={() => setModalDownloadRequestsOpen(true)}>
                        <h5>downloads</h5>
                      </button>
                    </div>
                  </div>

                  {waitingAPIRequest ? (
                    <div className="loading"></div>
                  ) : pageData?.length ? (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th align="center">
                              <input checked={allChecked} type="checkbox" onChange={() => handleCheck()} />
                            </th>
                            <th align="left">Nº</th>
                            <th align="left">Emissão</th>
                            <th align="left">Natureza da Operação</th>
                            <th align="left">Valor</th>
                            <th align="left">Chave</th>
                            <th align="left"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {pageData.map((item) => (
                            <TableRow key={item.id} item={item} />
                          ))}
                        </tbody>
                      </table>

                      <div className="table__footer">
                        {documents && documents.length > 5 ? (
                          <Pagination
                            count={pages}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePaginationChange}
                          />
                        ) : null}
                      </div>
                    </>
                  ) : documents === null ? (
                    <div className="table__warning">
                      <img src={filter} alt="filter" />
                      <h1>
                        utilize os filtros acima <br /> para listar os documentos
                      </h1>
                    </div>
                  ) : (
                    <div className="table__warning">
                      <img src={noDocument} alt="no-document" />
                      <h1>
                        não encontramos documentos <br /> para listar utilizando <br /> os filtros acima
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <DownloadRequests
        modalDownloadRequestsOpen={modalDownloadRequestsOpen}
        setModalDownloadRequestsOpen={setModalDownloadRequestsOpen}
      />

      <DownloadConfirmationItems
        downloadDetails={filterProps}
        downloadItems={downloadItems}
        setModalDownloadConfirmationOpen={setModalDownloadConfirmationItemsOpen}
        modalDownloadConfirmationOpen={modalDownloadConfirmationItemsOpen}
      />
    </div>
  )
}
