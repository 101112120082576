import { useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { Socket } from 'socket.io-client'
import { DocumentProps, TableContext } from '../../../contexts/TableContext'
import { api } from '../../../services/api'
import { matchFilesType } from '../../../utils/match-files-type'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import close from '../../../assets/icons/close.svg'

import './styles.css'
import analyticsEventTracker from '../../../utils/analytics-event-tracker'
import { error } from '../../../utils/browser-loggers'

export interface DownloadDetailsProps {
  month: string
  selectedYear: string
  documentType: string
  selectedSerie: number
  selectedClient: ClientProps
}

interface ClientProps {
  id: number
  razao_social: string
  series: number[]
}

interface FilterDocumentsResponse {
  data: DocumentProps[]
}

interface DownloadRequestsModalProps {
  downloadDetails: DownloadDetailsProps
  socket: Socket
  modalDownloadConfirmationOpen: boolean
  setModalDownloadConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>
  setWaitingDownloadRequest: React.Dispatch<React.SetStateAction<boolean>>
}

interface DownloadSocketCallback {
  bucket: string
  id_solicitacao: number
  socket: string
  url: string
}

export default function DownloadConfirmationMonth({
  downloadDetails,
  socket,
  setWaitingDownloadRequest,
  modalDownloadConfirmationOpen,
  setModalDownloadConfirmationOpen,
}: DownloadRequestsModalProps) {
  const gaEventTracker = analyticsEventTracker('DOWNLOAD')

  const { month, documentType, selectedClient, selectedSerie, selectedYear } = downloadDetails
  const { filterProps } = useContext(TableContext)
  const [socketChannel, setSocketChannel] = useState<string>('')
  const [notExistsDocumentsToDownload, setNotExistsDocumentsToDownload] = useState<boolean>(false)

  useEffect(() => {
    const newSocketChannel = uuid()
    setSocketChannel(newSocketChannel)
    socket.on(newSocketChannel, (client: DownloadSocketCallback) => {
      setWaitingDownloadRequest(false)
      window.open(`https://${client.bucket}.s3.sa-east-1.amazonaws.com/${client.url}`, '_blank')
    })
  }, [])

  async function verifyIfExistsAEqualRequest(data: DocumentProps[]): Promise<string | null> {
    const files = data.map((item) => item.id)

    try {
      const response = await api.get(
        `/solicitacoes_download/verificar?arr=${JSON.stringify(files)}&id_cliente=${selectedClient.id}`
      )
      const { url } = response.data
      return url
    } catch (err) {
      return null
    }
  }

  async function downloadAllDocumentsOfMonth(documentExtension: 'pdf' | 'xml') {
    gaEventTracker('MÊS')

    const request = await api.get(
      `/arquivos/filtrar/mes?client=${
        selectedClient.id
      }&year=${selectedYear}&series=${selectedSerie}&documentType=${matchFilesType(documentType)}&month=${month}`
    )

    const { data }: FilterDocumentsResponse = request

    if (data.length > 0) {
      const sameRequestBefore = await verifyIfExistsAEqualRequest(data)
      if (sameRequestBefore) {
        setWaitingDownloadRequest(false)
        window.open(`https://requests-zips.s3.sa-east-1.amazonaws.com/${sameRequestBefore}`, '_blank')
        return
      }

      setWaitingDownloadRequest(true)

      const files = data.map((item) => {
        return {
          id: item.id,
          fileName: item[`s3_${documentExtension}_nome_arquivo`],
          key: item.chave,
        }
      })

      await api
        .post('/s3/download', {
          arquivos: files,
          socket: socketChannel,
          tipo_documento: filterProps.type,
          id_cliente: selectedClient.id,
          bucket: `/${documentType}-${documentExtension}`,
        })
        .then((response) => setModalDownloadConfirmationOpen(false))
        .catch((err) => error('fail on download all documents of month', { err }))
    } else {
      setNotExistsDocumentsToDownload(true)
    }
  }

  return (
    <Modal
      open={modalDownloadConfirmationOpen}
      onClose={() => setModalDownloadConfirmationOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__box">
        <div className="modal__header">
          <h1>CONFIRMAÇÃO DE DOWNLOAD</h1>

          <img src={close} alt="close" onClick={() => setModalDownloadConfirmationOpen(false)} />
        </div>

        <div className="modal__body">
          {notExistsDocumentsToDownload ? (
            <p>
              Não existem documentos para serem baixados no mês selecionado, qualquer dúvida entre em contato com o seu
              cliente para solicitar suporte em relação a notas que não estão sendo listadas.
            </p>
          ) : (
            Object.keys(downloadDetails).length && (
              <>
                <p>
                  {documentType.toUpperCase()} / série {selectedSerie}
                </p>
                <p>
                  Período:{' '}
                  <b>
                    {month}/{selectedYear}
                  </b>
                </p>
                <p>
                  Cliente:
                  <b> {selectedClient.razao_social}</b>
                </p>
              </>
            )
          )}
        </div>

        <div className="modal__footer">
          <button className="confirm" onClick={() => downloadAllDocumentsOfMonth('xml')}>
            CONFIRMAR
          </button>
          <button
            className="cancel"
            onClick={() => {
              setModalDownloadConfirmationOpen(false)
              setNotExistsDocumentsToDownload(false)
            }}
          >
            CANCELAR
          </button>
        </div>
      </Box>
    </Modal>
  )
}
