import { useEffect, useState } from 'react'

import contabilidadeImage from '../../assets/images/contabilidade.png'

import checkedIcon from '../../assets/icons/checked-white.svg'
import arrowRightIcon from '../../assets/icons/arrow-right.svg'
import Toast from '../../components/Toast'

import { apiAuth } from '../../services/apiAuth'

import './styles.css'

export default function ConfirmedAccount() {
  const [waitingAPIResponse, setWaitingAPIResponse] = useState(true)

  const query = window.location.search
  const urlParams = new URLSearchParams(query)

  const HASH = urlParams.get('hash')

  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  useEffect(() => {
    confirmAccount()
  }, [])

  async function confirmAccount() {
    await apiAuth
      .post('/usuarios/email/confirmar', { hash: HASH })
      .then(() => {})
      .catch((uncaught) => {
        setWaitingAPIResponse(false)

        setToastType('error')
        setToastMessage(uncaught.data.error)
        setToastVisible(true)

        setTimeout(() => {
          setToastVisible(false)
        }, 5000)
      })
      .finally(() => {
        setWaitingAPIResponse(false)
      })
  }

  return (
    <div className="confirmed__account">
      <div className="container">
        <img src={contabilidadeImage} alt="silo-virtual" className="logo__app" />

        {waitingAPIResponse ? (
          <div className="loading"></div>
        ) : (
          <div className="confirmed">
            <img src={checkedIcon} alt="check-icon" />

            <h5>E-MAIL CONFIRMADO</h5>
          </div>
        )}

        <Toast visible={toastVisible} type={toastType} title={toastMessage} />

        <a href="/">
          FAZER LOGIN
          <img src={arrowRightIcon} alt="arrow-left" />
        </a>
      </div>
    </div>
  )
}
