import { useContext, useEffect, useState } from 'react'

import './styles.css'

import contabilidadeImage from '../../assets/images/contabilidade.png'

import emailIcon from '../../assets/icons/email.svg'
import lockIcon from '../../assets/icons/lock.svg'
import Toast from '../../components/Toast'

import { AuthContext } from '../../contexts/AuthContext'
import InviteInstructions from '../../components/Modals/InviteInstructions'

export default function Login() {
  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const { signIn, invite } = useContext(AuthContext)

  const [modalInviteInstructionsOpen, setModalInviteInstructionsOpen] = useState(false)

  useEffect(() => {
    if (invite) {
      setModalInviteInstructionsOpen(true)
    }
  }, [])

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    login()
  }

  async function login() {
    const returnSignIn = await signIn({ user, password }).finally(() => setWaitingRequest(false))

    if (!returnSignIn) {
      setToastType('error')
      setToastMessage(returnSignIn === false ? 'CREDENCIAIS INCORRETAS' : 'FALHA NA REQUISIÇÃO')
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      setWaitingRequest(false)
    }
  }

  return (
    <div className="App">
      <div className="form__box">
        <form onSubmit={(e) => handleSubmit(e)}>
          <img src={contabilidadeImage} alt="silo-virtual" className="logo__app" />

          <div className="input__box">
            <input
              type="text"
              name="cpf"
              required
              placeholder="E-MAIL"
              onChange={(e) => setUser(e.target.value)}
            />
            <img src={emailIcon} alt="e-mail" />
          </div>

          <div className="input__box">
            <input
              type="password"
              required
              placeholder="SENHA"
              onChange={(e) => setPassword(e.target.value)}
            />
            <img src={lockIcon} alt="password" />
          </div>

          <Toast visible={toastVisible} type={toastType} title={toastMessage} />

          <button
            disabled={waitingRequest}
            className={waitingRequest ? 'button__loading' : ''}
            type="submit"
          >
            <span>ENTRAR</span>
          </button>

          <a href="/forgot_password">ESQUECI MINHA SENHA</a>

          <hr />

          <div className="create__account">
            <h5>AINDA NÃO POSSUI UMA CONTA?</h5>
            <a href="/signup">CADASTRE-SE</a>
          </div>
        </form>
      </div>

      <InviteInstructions
        setModalInviteInstructionsOpen={setModalInviteInstructionsOpen}
        modalInviteInstructionsOpen={modalInviteInstructionsOpen}
      />
    </div>
  )
}
