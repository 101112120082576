import { variables } from '../variables'

import axios from 'axios'
import Cookies from 'js-cookie'

const token = Cookies.get('__TOKEN')

export const apiAuth = axios.create({
  baseURL: variables.API_AUTH,
})

apiAuth.interceptors.response.use((response) => {
  if (response.status === 401) {
    Cookies.remove('__TOKEN')

    window.location.href = '/'
  }

  return response
})

if (token) {
  apiAuth.defaults.headers.common['Authorization'] = `Bearer ${token}`
  apiAuth.defaults.headers.post['Content-Type'] = 'application/json'
}
