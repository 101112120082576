import { useState } from 'react'

import { apiAuth } from '../../services/apiAuth'

import contabilidadeImage from '../../assets/images/contabilidade.png'
import lockIcon from '../../assets/icons/lock.svg'
import arrowLeftIcon from '../../assets/icons/arrow-left.svg'

import Toast from '../../components/Toast'

import './styles.css'

export default function RecoverPassword() {
  const query = window.location.search
  const urlParams = new URLSearchParams(query)

  const HASH = urlParams.get('hash')

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [toastType, setToastType] = useState<'error' | 'alert'>('alert')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  function handleSubmit(e: any) {
    e.preventDefault()
    setWaitingRequest(true)
    updatePassword()
  }

  async function updatePassword() {
    if (password !== confirmPassword) {
      setToastType('error')
      setToastMessage('SENHAS DIFERENTES')
      setToastVisible(true)
      setWaitingRequest(false)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      return
    }

    await apiAuth
      .patch('/usuarios/senha', { email: HASH, senha: password })
      .then(() => {
        setWaitingRequest(false)

        window.location.href = '/recovered_password'
      })
      .catch((uncaught) => {
        setWaitingRequest(false)
        setToastType('error')
        setToastMessage(uncaught.data.error)
        setToastVisible(true)
        setTimeout(() => {
          setToastVisible(false)
        }, 5000)
      })
  }

  return (
    <div className="recover__password">
      <form onSubmit={(e) => handleSubmit(e)}>
        <img src={contabilidadeImage} alt="silo-virtual" className="logo__app" />

        <h1>RECUPERAÇÃO DE SENHA</h1>

        <div className="input__box">
          <input
            type="password"
            required
            placeholder="NOVA SENHA"
            onChange={(e) => setPassword(e.target.value)}
          />
          <img src={lockIcon} alt="" />
        </div>

        <div className="input__box">
          <input
            type="password"
            required
            placeholder="CONFIRMAR SENHA"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <img src={lockIcon} alt="" />
        </div>

        <Toast visible={toastVisible} type={toastType} title={toastMessage} />

        <button
          disabled={waitingRequest}
          className={waitingRequest ? 'button__loading' : ''}
          type="submit"
        >
          <span>ALTERAR SENHA</span>
        </button>

        <a href="/">
          <img src={arrowLeftIcon} alt="arrow-left" />
          VOLTAR PARA O LOGIN
        </a>
      </form>
    </div>
  )
}
