import { useContext } from 'react'
import { TableContext } from '../../contexts/TableContext'

import './styles.css'

export default function TableHeader() {
  const { documentType, handleDocumentTypeSelection } = useContext(TableContext)

  const types = ['NFe', 'NFCe', 'MDFe', 'CTe']

  return (
    <div className="table__header">
      {types.map((type) => (
        <div
          key={type}
          className={`btn ${documentType === type.toLowerCase() && 'selected'}`}
          onClick={() => handleDocumentTypeSelection(type.toLowerCase())}
        >
          <h3>{type}</h3>
        </div>
      ))}
    </div>
  )
}
