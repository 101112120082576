import { FormEvent, useEffect, useState } from 'react'

import Cookies from 'js-cookie'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import close from '../../../../assets/icons/close.svg'

import { apiAuth } from '../../../../services/apiAuth'

import './styles.css'
import Toast from '../../../Toast'

export interface DownloadRequestsProps {
  id: number
  socket: string
  url: string
  tipo_documento: number
  arquivos: string
  data_cadastro: string
}

interface DownloadRequestsModalProps {
  modalChangeUsernameOpen: boolean
  setModalChangeUsernameOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ChangeUsername({
  modalChangeUsernameOpen,
  setModalChangeUsernameOpen,
}: DownloadRequestsModalProps) {
  const userId = Cookies.get('__ID')
  const username = Cookies.get('__CONTAB')

  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  const [newUsername, setNewUsername] = useState('')

  async function changeUsername(e: FormEvent) {
    e.preventDefault()

    setWaitingRequest(true)

    const { data, status } = await apiAuth.patch(`/usuarios/${userId}/razao_social`, {
      razao_social: newUsername,
    })

    if (!(status === 200)) {
      setToastType('error')
      setToastMessage('FALHA NA REQUISIÇÃO')
      setToastVisible(true)
      setTimeout(() => {
        setToastVisible(false)
      }, 5000)
      setWaitingRequest(false)

      return
    }

    Cookies.remove('__CONTAB')
    Cookies.set('__CONTAB', data.razao_social)
    setModalChangeUsernameOpen(false)
    setWaitingRequest(false)
  }

  return (
    <Modal
      open={modalChangeUsernameOpen}
      onClose={() => setModalChangeUsernameOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__change__username">
        <div className="modal__header">
          <h1>ALTERAR RAZÃO SOCIAL</h1>

          <img src={close} alt="close" onClick={() => setModalChangeUsernameOpen(false)} />
        </div>

        <div className="modal__body">
          <form onSubmit={(e) => changeUsername(e)}>
            <input
              required
              type="text"
              placeholder={username}
              autoFocus
              onChange={(e) => setNewUsername(e.target.value)}
            />

            <Toast visible={toastVisible} type={toastType} title={toastMessage} />

            <div className="buttons">
              <button
                disabled={waitingRequest}
                className={`confirm ${waitingRequest && 'button__loading'}`}
                type="submit"
              >
                <span>CONFIRMAR</span>
              </button>
              <button className="cancel" onClick={() => setModalChangeUsernameOpen(false)}>
                <span>CANCELAR</span>
              </button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  )
}
