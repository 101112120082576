import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { TableContext } from './TableContext'
import { AuthContext } from './AuthContext'
import { api } from '../services/api'
import { error } from '../utils/browser-loggers'

import Cookies from 'js-cookie'

interface CompaniesContextType {
  releasedClients: ReleasedClient[]
  selectedClient: ReleasedClient
  selectedSerie: number
  setSelectedSerie: Dispatch<SetStateAction<number>>
  setSelectedClient: Dispatch<SetStateAction<ReleasedClient>>
  handleClientSelection: (clientId: number) => void
  getAccountingReleasedClients: () => void

  clientCompanies?: ReleasedClient[]
}

export interface ReleasedClient {
  id: number
  razao_social: string
  series: number[]
  anos_emitidos: { ano: number }[]
}

export const CompaniesContext = createContext({} as CompaniesContextType)

export function CompaniesProvider({ children }: any) {
  const userId = Cookies.get('__ID')
  const token = Cookies.get('__TOKEN')
  const isClient = Number(Cookies.get('__IS_CLIENT'))
  const userEmail = Cookies.get('@CONTAB:email')

  const [releasedClients, setReleasedClients] = useState<ReleasedClient[]>([])
  const [selectedClient, setSelectedClient] = useState({} as ReleasedClient)
  const [selectedSerie, setSelectedSerie] = useState<number>(0)

  const [clientCompanies, setClientCompanies] = useState<ReleasedClient[]>([])

  const { handleFilterChange } = useContext(TableContext)
  const { isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    if (token) {
      if (isClient) {
        getClientCompanies()
      } else {
        getAccountingReleasedClients()
      }
    }
  }, [isAuthenticated])

  function handleClientSelection(clientId: number) {
    const client = releasedClients.find((client) => client.id === clientId)
    window.localStorage.setItem('__CLIENT', JSON.stringify(client))
    setSelectedClient(client || ({} as ReleasedClient))
    setSelectedSerie(client?.series[0] || 0)
  }

  async function getClientCompanies() {
    try {
      const { data } = await api.get(`/clientes/empresas?email=${userEmail}`)
      setReleasedClients(data)

      if (data.length) {
        setSelectedClient(data[0])
        setSelectedSerie(data[0].series || undefined)

        handleFilterChange({ key: 'series', value: data[0].series })
        handleFilterChange({ key: 'client', value: data[0].id })
      }
    } catch (err) {
      error('fail on load client companies', { err })
    }
  }

  function getAccountingReleasedClients() {
    api
      .get(`/clientes/liberados/usuario/${userId}`)
      .then((response) => {
        setReleasedClients(response.data)

        const alreadyExistsASelectedClient = window.localStorage.getItem('__CLIENT')

        if (alreadyExistsASelectedClient && !Object.keys(selectedClient).length) {
          const parsedSavedClientOfLocalStorage = JSON.parse(alreadyExistsASelectedClient)

          setSelectedClient(parsedSavedClientOfLocalStorage)
          setSelectedSerie(parsedSavedClientOfLocalStorage.series[0])

          handleFilterChange({ key: 'series', value: parsedSavedClientOfLocalStorage.series[0] })
          handleFilterChange({ key: 'client', value: parsedSavedClientOfLocalStorage.id })

          return
        }

        if (!alreadyExistsASelectedClient && window.location.pathname !== '/dashboard') {
          window.location.href = '/dashboard'
          return
        }

        if (response.data.length === 1) {
          setSelectedClient(response.data[0])
          setSelectedSerie(response.data[0].series[0] || undefined)

          handleFilterChange({ key: 'series', value: response.data[0].series[0] })
          handleFilterChange({ key: 'client', value: response.data[0].id })
        }
      })
      .catch((err) => {
        error('fail on get accounting released clients', { err })
      })
  }

  return (
    <CompaniesContext.Provider
      value={{
        releasedClients,
        getAccountingReleasedClients,
        selectedClient,
        handleClientSelection,
        selectedSerie,
        setSelectedSerie,
        setSelectedClient,
        clientCompanies,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  )
}
