import { useState, useEffect, useContext } from 'react'
import { api } from '../../../services/api'
import { CompaniesContext } from '../../../contexts/CompaniesContext'

import Cookies from 'js-cookie'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Download from '@mui/icons-material/CloudDownload'

import close from '../../../assets/icons/close.svg'
import noDocument from '../../../assets/icons/no__document.svg'

import { Pagination } from '@mui/material'

import './styles.css'
import { error } from '../../../utils/browser-loggers'

export interface DownloadRequestsProps {
  id: number
  socket: string
  url: string
  tipo_documento: number
  arquivos: string
  data_cadastro: string
  contabilidade: string
}

interface DownloadRequestsModalProps {
  modalDownloadRequestsOpen: boolean
  setModalDownloadRequestsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DownloadRequests({
  modalDownloadRequestsOpen,
  setModalDownloadRequestsOpen,
}: DownloadRequestsModalProps) {
  const userId = Cookies.get('__ID')
  const isClient = Number(Cookies.get('__IS_CLIENT'))
  const COMPONENTS_PER_PAGE = 10

  const { selectedClient } = useContext(CompaniesContext)

  const [downloadRequests, setDownloadRequests] = useState<DownloadRequestsProps[]>([])

  const [waitingRequest, setWaitingRequest] = useState(true)

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[]>([downloadRequests.slice(0, COMPONENTS_PER_PAGE)])

  useEffect(() => {
    getUserDownloadRequests()

    handlePaginationChange(null, 1)
  }, [modalDownloadRequestsOpen])

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (downloadRequests?.length) {
      setPageData(downloadRequests.slice(startIndex, lastIndex))
    }
  }

  function matchDocumentType(type: number) {
    if (type === 1) return 'nfe'
    if (type === 2) return 'nfce'
    if (type === 3) return 'cte'
    if (type === 4) return 'mdfe'
  }

  function getUserDownloadRequests() {
    const url = isClient
      ? `/solicitacoes_download/cliente/${userId}`
      : `/solicitacoes_download/usuario/${userId}/cliente/${selectedClient.id}`

    if (selectedClient.id) {
      api
        .get(url)
        .then((response) => {
          const { data } = response

          setDownloadRequests(data)

          const dataSplited = String(data && data.length / COMPONENTS_PER_PAGE).split('.')

          if (dataSplited[1]) {
            setPages(Number(dataSplited[0]) + 1)
          }

          setPageData(data.slice(0, COMPONENTS_PER_PAGE))

          setWaitingRequest(false)
        })
        .catch((err) => {
          error('fail on get user download requests', { err })
        })
    }
  }

  return (
    <Modal
      open={modalDownloadRequestsOpen}
      onClose={() => setModalDownloadRequestsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__box__requests">
        <div className="modal__header">
          <h1>DOWNLOADS</h1>

          <img src={close} alt="close" onClick={() => setModalDownloadRequestsOpen(false)} />
        </div>

        <div className="modal__body">
          <div className="request">
            {waitingRequest ? (
              <div className="loading"></div>
            ) : downloadRequests.length > 0 ? (
              <>
                <table>
                  <thead>
                    <tr>
                      {isClient ? <th align="left">contabilidade</th> : null}
                      <th align="left">tipo</th>
                      <th align="right">data</th>
                      <th>quantidade</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {pageData.map((request) => (
                      <tr key={request.id}>
                        {isClient ? <td align="left">{request.contabilidade || '-'}</td> : null}
                        <td>{matchDocumentType(request.tipo_documento)?.toUpperCase()}</td>
                        <td align="right">{new Date(request.data_cadastro).toLocaleDateString('pt-BR')}</td>
                        <td align="center">{JSON.parse(request.arquivos).length} arquivo(s)</td>
                        <td align="center" className="flex">
                          <a href={`https://requests-zips.s3.sa-east-1.amazonaws.com/${request.url}`} download>
                            <Download fontSize="small" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="table__footer">
                  {downloadRequests.length > 5 ? (
                    <Pagination
                      count={pages}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePaginationChange}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <div className="table__warning">
                <img src={noDocument} alt="no-document" />
                <h1>sem solicitações para exibir</h1>
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  )
}
