import { DocumentProps, TableContext } from '../../contexts/TableContext'
import { useContext, useEffect, useState } from 'react'
import { CompaniesContext } from '../../contexts/CompaniesContext'
import { matchFilesType } from '../../utils/match-files-type'
import { api } from '../../services/api'
import { Link } from 'react-router-dom'

import check from '../../assets/icons/check.svg'
import eye from '../../assets/icons/eye.svg'
import zip from '../../assets/icons/zip.svg'
import print from '../../assets/icons/print__blue.svg'
import excel from '../../assets/icons/excel.svg'
import block from '../../assets/icons/block.svg'

import PDFDocumentsGenerator from '../PDFDocumentsGenerator'

import * as XLSX from 'xlsx'

import './styles.css'
import { error } from '../../utils/browser-loggers'

interface HomeTableMonthProps {
  description: string
  month: string
  actualMonth: string
  selectedYear: string
  handleDownloadRequest: (month: string) => void
}

interface TableXLSXData {
  NUMERO: number
  DATA_EMISSAO: string
  NATUREZA_OPERACAO: string
  VALOR: string
  CHAVE: string
}

export default function HomeTableMonth({
  description,
  month,
  actualMonth,
  selectedYear,
  handleDownloadRequest,
}: HomeTableMonthProps) {
  const actualYear = new Date().getFullYear()

  const { selectedClient, selectedSerie } = useContext(CompaniesContext)
  const { documentType, filterProps } = useContext(TableContext)

  const [waitingDownloadXLS, setWaitingDownloadXLS] = useState(false)
  const [waitingDownloadPDF, setWaitingDownloadPDF] = useState(false)

  const [documentsToMountTableToGenerateExcelReport, setDocumentsToMountTableToGenerateExcelReport] = useState<
    DocumentProps[]
  >([])

  const monthIsAvailable =
    Number(actualYear) > Number(selectedYear) ? true : Number(month) <= Number(actualMonth) ? true : false

  useEffect(() => {}, [documentsToMountTableToGenerateExcelReport])

  function downloadPDF(month: string) {
    setWaitingDownloadPDF(true)
    api
      .get(
        `/arquivos/filtrar/mes?month=${month}&year=${selectedYear}&documentType=${matchFilesType(
          documentType
        )}&series=${selectedSerie}&client=${selectedClient.id}`
      )
      .then((response) => {
        PDFDocumentsGenerator(response.data, null, null, month, selectedYear, documentType)
        setWaitingDownloadPDF(false)
      })
      .catch((err) => error('fail on download pdf of selected files in home table month', { err }))
  }

  async function downloadXLS(month: string) {
    setWaitingDownloadXLS(true)

    const request = await api.get(
      `/arquivos/filtrar/mes?client=${
        selectedClient.id
      }&year=${selectedYear}&series=${selectedSerie}&documentType=${matchFilesType(documentType)}&month=${month}`
    )

    const { data, status } = request

    if (status === 400 || status === 500) {
      error('fail on download xls of selected files in home table month', { err: data })
    }

    setDocumentsToMountTableToGenerateExcelReport(data)

    generateXLSX(data)
  }

  function generateXLSX(data: DocumentProps[]) {
    const documents = adaptDataToGenerateTable(data)

    const TABLE = document.createElement('table')
    const THEAD_ELEMENT = document.createElement('thead')
    const THEAD_TR_ELEMENT = document.createElement('tr')

    const tableHeaders = ['NUMERO', 'DATA_EMISSAO', 'NATUREZA_OPERACAO', 'VALOR', 'CHAVE']

    for (const header of tableHeaders) {
      const TH_ELEMENT = document.createElement('th')
      const TH_ELEMENT_VALUE = document.createTextNode(header)

      TH_ELEMENT.appendChild(TH_ELEMENT_VALUE)
      THEAD_TR_ELEMENT.appendChild(TH_ELEMENT)
    }

    const TBODY_ELEMENT = document.createElement('tbody')

    for (const doc of documents) {
      const TBODY_TR_ELEMENT = document.createElement('tr')

      for (const value of Object.values(doc)) {
        const TD_ELEMENT = document.createElement('td')
        const TD_ELEMENT_VALUE = document.createTextNode(value)
        TD_ELEMENT.appendChild(TD_ELEMENT_VALUE)
        TBODY_TR_ELEMENT.appendChild(TD_ELEMENT)
      }

      TBODY_ELEMENT.appendChild(TBODY_TR_ELEMENT)
    }

    THEAD_ELEMENT.appendChild(THEAD_TR_ELEMENT)

    TABLE.appendChild(THEAD_ELEMENT)
    TABLE.appendChild(TBODY_ELEMENT)

    const file = XLSX.utils.json_to_sheet(documents, {
      header: tableHeaders,
      skipHeader: false,
    })
    const book = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(book, file)
    XLSX.write(book, { type: 'base64' })
    XLSX.writeFileXLSX(book, 'contab.' + 'xlsx')
    setWaitingDownloadXLS(false)
  }

  function adaptDataToGenerateTable(data: DocumentProps[]): TableXLSXData[] {
    const tableData: TableXLSXData[] = []

    for (const item of data) {
      tableData.push({
        NUMERO: item.numero,
        DATA_EMISSAO: new Date(item.data_emissao).toLocaleDateString('pt-BR'),
        NATUREZA_OPERACAO: item.cfop,
        VALOR: Number(item.valor).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
        CHAVE: item.chave,
      })
    }

    return tableData
  }

  return (
    <div className="month" key={month}>
      <div className="title">
        <img src={`${monthIsAvailable ? check : block}`} alt="check" />

        <h1 className={`${monthIsAvailable ? '' : 'disabled'}`}>
          {description} / {selectedYear}
        </h1>
      </div>

      {monthIsAvailable && (
        <div className="actions">
          <Link to="/docs" state={{ month: month, year: selectedYear }} reloadDocument={false}>
            <img src={eye} alt="check" title="DETALHAR NOTAS" />
          </Link>
          <img src={zip} alt="zip" title="BAIXAR ZIP" onClick={() => handleDownloadRequest(month)} />
          {!waitingDownloadPDF ? (
            <img src={print} alt="print" title="IMPRIMIR RELATÓRIO (PDF)" onClick={() => downloadPDF(month)} />
          ) : (
            <div className="loading"></div>
          )}
          {!waitingDownloadXLS ? (
            <img src={excel} alt="excel" title="IMPRIMIR RELATÓRIO (EXCEL)" onClick={() => downloadXLS(month)} />
          ) : (
            <div className="loading"></div>
          )}
        </div>
      )}

      <table id="table">
        <thead>
          <tr>
            <th align="left">Nº</th>
            <th align="left">DT. EMISSÃO</th>
            <th align="left">CFOP</th>
            <th align="left">VALOR</th>
            <th align="left">CHAVE</th>
          </tr>
        </thead>

        <tbody>
          {documentsToMountTableToGenerateExcelReport.map((item) => (
            <tr>
              <td>{item.numero}</td>
              <td>{new Date(new Date(item.data_emissao).setHours(24)).toLocaleDateString('pt-BR')}</td>
              <td>{item.cfop}</td>
              <td>
                {item.valor.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </td>
              <td>
                <span>{item.chave}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
