import ReactGA from 'react-ga'

const analyticsEventTracker = (category = 'Contabilidade') => {
  const eventTracker = (action = 'ta', label = 'tl') => {
    ReactGA.event({ category, action, label })
  }
  return eventTracker
}

export default analyticsEventTracker
