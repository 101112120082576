import { useContext, useEffect, useState } from 'react'
import { TableContext } from '../../contexts/TableContext'
import { CompaniesContext } from '../../contexts/CompaniesContext'
import { api } from '../../services/api'

import arrowUpperight from '../../assets/icons/arrow-upperight-blue.svg'
import leaf from '../../assets/images/leaf.png'
import mobile from '../../assets/images/mobile.png'
import download from '../../assets/icons/download.svg'

import Header from '../../components/Header'
import TableHeader from '../../components/TableHeader'
import SubHeader from '../../components/SubHeader'
import Companies from '../../components/Modals/Companies'
import HomeTableMonth from '../../components/HomeTableMonth'
import DownloadConfirmationMonth, { DownloadDetailsProps } from '../../components/Modals/DownloadConfirmationMonth'

import { Socket } from 'socket.io-client'

import './styles.css'
import DownloadRequests from '../../components/Modals/DownloadRequests'
import Cookies from 'js-cookie'
import { error } from '../../utils/browser-loggers'

interface AnualStatistics {
  quantidade: number
  tipo_documento: string
}

interface MonthStatistics {
  quantidade: number
}

interface HomePageProps {
  socket: Socket
}

export default function Home({ socket }: HomePageProps) {
  const isClient = Cookies.get('__IS_CLIENT')

  const { documentType, selectedYear, setSelectedYear } = useContext(TableContext)
  const { selectedSerie, selectedClient, getAccountingReleasedClients, clientCompanies } = useContext(CompaniesContext)

  const [modalDownloadConfirmationOpen, setModalDownloadConfirmationOpen] = useState<boolean>(false)
  const [modalCompaniesOpen, setModalCompaniesOpen] = useState(!!selectedClient)
  const [modalDownloadRequestsOpen, setModalDownloadRequestsOpen] = useState(false)

  const [waitingDownloadRequest, setWaitingDownloadRequest] = useState(false)

  const [downloadDetails, setDownloadDetails] = useState({} as DownloadDetailsProps)
  const [anualStatistics, setAnualStatistics] = useState<AnualStatistics[]>([])
  const [monthStatistics, setMonthStatistics] = useState<MonthStatistics>({} as MonthStatistics)

  const monthsList = [
    { description: 'JANEIRO', month: '01' },
    { description: 'FEVEREIRO', month: '02' },
    { description: 'MARÇO', month: '03' },
    { description: 'ABRIL', month: '04' },
    { description: 'MAIO', month: '05' },
    { description: 'JUNHO', month: '06' },
    { description: 'JULHO', month: '07' },
    { description: 'AGOSTO', month: '08' },
    { description: 'SETEMBRO', month: '09' },
    { description: 'OUTUBRO', month: '10' },
    { description: 'NOVEMBRO', month: '11' },
    { description: 'DEZEMBRO', month: '12' },
  ]
  const actualMonth = (new Date().getMonth() + 1).toString()
  const actualYear = new Date().getFullYear()

  useEffect(() => {
    getAnualStatistics()
    getMonthStatistics()

    const invite = localStorage.getItem('invite')

    if (invite) {
      registerClientToUserWithInvite(invite)
    }
  }, [selectedClient])

  function registerClientToUserWithInvite(invite: string) {
    localStorage.removeItem('invite')

    api
      .put(`/convites/registrar`, { hash: invite })
      .then((response) => {
        getAccountingReleasedClients()
      })
      .catch((err) => error('fail on register client to user with invite', { err }))
  }

  function getAnualStatistics() {
    api
      .get(`/arquivos/filtrar/estatisticas/anual?ano=${actualYear}&idCliente=${selectedClient.id}`)
      .then((response) => {
        setAnualStatistics(response.data)
      })
      .catch((err) => {
        error('fail on get anual statistics', { err })
      })
  }

  function getMonthStatistics() {
    const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')

    api
      .get(`/arquivos/filtrar/estatisticas/mensal?idCliente=${selectedClient.id}&mes=${actualMonth}&ano=${actualYear}`)
      .then((response) => {
        setMonthStatistics(response.data)
      })
      .catch((err) => {
        error('fail on get month statistics', { err })
      })
  }

  function handleDownloadRequest(month: string) {
    setDownloadDetails({
      month,
      selectedYear,
      documentType,
      selectedSerie,
      selectedClient,
    })
    setModalDownloadConfirmationOpen(true)
  }

  return (
    <main>
      <Header />

      <div className="container">
        {Object.keys(selectedClient).length ? (
          <>
            <SubHeader />

            <div className="tables">
              <div className="main box">
                <TableHeader />

                <div className="content">
                  <div className="years__buttons">
                    <div className="years">
                      {selectedClient.anos_emitidos?.map((ano, index) => (
                        <button
                          key={index}
                          className={selectedYear === String(ano.ano) ? 'checked' : ''}
                          onClick={() => setSelectedYear(String(ano.ano))}
                        >
                          {ano.ano}
                        </button>
                      ))}
                    </div>

                    <div className="downloads">
                      <button
                        id="download"
                        className={waitingDownloadRequest ? 'button__loading' : ''}
                        onClick={() => setModalDownloadRequestsOpen(true)}
                      >
                        <img src={download} alt="download" />
                      </button>
                    </div>
                  </div>

                  <div className="months__list">
                    {monthsList.map((month) => (
                      <HomeTableMonth
                        description={month.description}
                        month={month.month}
                        actualMonth={actualMonth}
                        handleDownloadRequest={handleDownloadRequest}
                        selectedYear={selectedYear}
                        key={month.month}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="stats">
                <div className="info box">
                  <div className="box__info__header">
                    <h1>ESTATÍSTICAS</h1>
                  </div>

                  <div className="body">
                    <h1>DOCUMENTOS EMITIDOS NO ANO</h1>

                    <div className="numbers">
                      {anualStatistics.map((docType) => (
                        <div key={docType.tipo_documento} className="type">
                          <h5>{docType.tipo_documento}</h5>
                          <h4>{docType.quantidade}</h4>
                        </div>
                      ))}
                    </div>

                    <h1>NESSE MÊS FORAM EMITIDOS {monthStatistics.quantidade} DOCUMENTOS</h1>
                  </div>
                </div>

                <div className="nun box">
                  <div className="box__info__header">
                    <h1>RURAL HUB</h1>
                  </div>

                  <div className="body">
                    <img src={leaf} alt="leaf" />

                    <div className="body__info">
                      <p>Conheça mais um pouco sobre nós e sobre nossas soluções, seja bem vindo ao nosso site.</p>

                      <a href="">
                        CONHECER
                        <img src={arrowUpperight} alt="arrow-upperight" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="nun box">
                  <div className="box__info__header">
                    <h1>SILO VIRTUAL</h1>
                  </div>

                  <div className="body">
                    <img src={mobile} alt="leaf" />

                    <div className="body__info">
                      <p>
                        Conheça o Silo Virtual, a nossa plataforma que conecta o armazém com o produtor independente do
                        lugar ou hora.
                      </p>

                      <a href="">
                        IR PARA O SITE
                        <img src={arrowUpperight} alt="arrow-upperight" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Companies modalCompaniesOpen={modalCompaniesOpen} setModalCompaniesOpen={setModalCompaniesOpen} />
        )}
      </div>

      <DownloadConfirmationMonth
        downloadDetails={downloadDetails}
        socket={socket}
        setWaitingDownloadRequest={setWaitingDownloadRequest}
        setModalDownloadConfirmationOpen={setModalDownloadConfirmationOpen}
        modalDownloadConfirmationOpen={modalDownloadConfirmationOpen}
      />

      <DownloadRequests
        setModalDownloadRequestsOpen={setModalDownloadRequestsOpen}
        modalDownloadRequestsOpen={modalDownloadRequestsOpen}
      />
    </main>
  )
}
