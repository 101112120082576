import { useContext, useState } from 'react'

import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

import { CompaniesContext } from '../../../contexts/CompaniesContext'
import { FilterProps, TableContext } from '../../../contexts/TableContext'

import close from '../../../assets/icons/close.svg'

import './styles.css'
import analyticsEventTracker from '../../../utils/analytics-event-tracker'

interface DownloadRequestsModalProps {
  downloadDetails: FilterProps
  downloadItems: (documentExtension: 'pdf' | 'xml') => void
  modalDownloadConfirmationOpen: boolean
  setModalDownloadConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function DownloadConfirmationItems({
  downloadDetails,
  downloadItems,
  modalDownloadConfirmationOpen,
  setModalDownloadConfirmationOpen,
}: DownloadRequestsModalProps) {
  const gaEventTracker = analyticsEventTracker('DOWNLOAD')

  const { initialDate, finalDate, series } = downloadDetails
  const { documentType, selectedItems } = useContext(TableContext)
  const { selectedClient } = useContext(CompaniesContext)

  const [documentExtension, setDocumentExtension] = useState<'xml' | 'pdf'>('xml')

  return (
    <Modal
      open={modalDownloadConfirmationOpen}
      onClose={() => setModalDownloadConfirmationOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal__box">
        <div className="modal__header">
          <h1>CONFIRMAÇÃO DE DOWNLOAD</h1>

          <img src={close} alt="close" onClick={() => setModalDownloadConfirmationOpen(false)} />
        </div>

        <div className="modal__body">
          <p>
            {selectedItems.length} arquivos ({documentType.toUpperCase()} / série {series})
          </p>
          <p>
            Período:
            <b> {initialDate?.split('-').reverse().join('/')}</b> a <b>{finalDate?.split('-').reverse().join('/')}</b>
          </p>
          <p>
            Cliente:
            <b> {selectedClient.razao_social}</b>
          </p>

          {/* <h1 className="type">
            tipo de documento:{' '}
            <div className="buttons">
              <button
                className={`${documentExtension === 'pdf' && 'selected'}`}
                onClick={() => setDocumentExtension('pdf')}
              >
                PDF
              </button>
              <button
                className={`${documentExtension === 'xml' && 'selected'}`}
                onClick={() => setDocumentExtension('xml')}
              >
                XML
              </button>
            </div>
          </h1> */}
        </div>

        <div className="modal__footer">
          <button
            className="confirm"
            onClick={() => {
              downloadItems(documentExtension)
              gaEventTracker('SELEÇÃO')
            }}
          >
            CONFIRMAR
          </button>
          <button className="cancel" onClick={() => setModalDownloadConfirmationOpen(false)}>
            CANCELAR
          </button>
        </div>
      </Box>
    </Modal>
  )
}
